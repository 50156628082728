import React from 'react';
import { Layout } from '../components/Layout';

import 'src/css/globalStyles.css';
import { navigate, Link } from 'gatsby';

import styles from './styles.module.css';

import { Puzzle } from 'src/model/PuzzleData';
import { ButtonList } from 'src/components/ButtonList';
import { PuzzleTileContainer } from 'src/components/PuzzleTileContainer';
import { WelcomeTextBlock } from 'src/components/WelcomeTextBlock';
import { ArrowButton } from 'src/components/ArrowButton';
import { KeystoneUser, UserManager, signInAsGuestUserIfRequired } from 'src/helpers/UserManager';
import { InterestManager } from 'src/helpers/InterestManager';
import { Interest } from 'src/model/Interest';
import { isSignUpRequired } from 'src/helpers/SignUpThresholdHelper';
import { PuzzleManager } from 'src/helpers/PuzzleManager';
import { PuzzleSessionManager } from 'src/helpers/PuzzleSessionManager';
import { NotificationOperations } from 'src/model/Notifications';
import { CaseStudy, CaseStudyManager } from 'src/helpers/CaseStudyManager';
import { ProfileSelection } from 'src/components/ProfileSelection';
import { ProductManager, Product } from 'src/helpers/ProductManager/ProductManager';
import { QuestionnaireSelection } from 'src/components/QuestionnaireSelection';
import { RegistrationManager, Registration } from 'src/helpers/RegistrationManager';

import TagManager from 'react-gtm-module';
import { SettingsManager } from 'src/helpers/SettingsManager';
import { DeliveryManager } from 'src/helpers/DeliveryManager/DeliveryManager';
import { Spinner } from 'src/components/Spinner';
import { AssessmentComplete } from 'src/components/AssessmentComplete';
import { CouponCode } from 'src/helpers/CouponCodeManager/CouponCode';
import { CouponCodeManager } from 'src/helpers/CouponCodeManager';
//import LogRocket from 'logrocket';

enum pages {
	QuestionnaireSelection,
	AssessmentComplete,
	ProfileSelection,
	Interests,
	Puzzles,
}

const tagManagerArgs = {
	gtmId: 'GTM-W53NBLJ',
	datalayer: {
		page: 'Welcome',
		userID: '007',
	},
	dataLayerName: 'PageDataLayer',
};

interface Props {
	intro?: string;
	puzzleData?: Puzzle[];
}

interface WelcomeInterest extends Interest {
	selected: boolean;
}

interface State {
	loading: boolean;
	interests: WelcomeInterest[];
	appropriatePuzzles: Puzzle[];
	showPuzzles: boolean;
	authUser: KeystoneUser | undefined;
	completedPuzzles: number[];
	codingPopupShown: boolean;
	caseStudies: CaseStudy[];
	showQuestionnaire: boolean;
	productData?: Product[];
	allPuzzlesComplete: boolean;
	userRegistration?: Registration;
	userIsAssessmentAndRecruitment: boolean;
	profileDetailsComplete: boolean;
	showPaidProfileAd: boolean;
	deliveriesExist: boolean;
	inEligibleCountry: boolean;
	currentPage: number;
	couponCode: CouponCode | undefined;
}

const assignInterests = async (signedInUser: any, interests: Interest[]): Promise<boolean> => {
	if (signedInUser) {
		const success = await UserManager.setInterestsForAuthenticatedUser(interests);
		if (!success) {
			console.error(`Failed to add interests for user: ${signedInUser.email}`);
		}
		return success;
	} else {
		console.error('Failed to authenticate.');
		return false;
	}
};

class WelcomeComponent extends React.Component<Props, State> {
	state: State = {
		loading: true,
		interests: [],
		appropriatePuzzles: [],
		showPuzzles: false,
		authUser: undefined,
		completedPuzzles: [],
		codingPopupShown: false,
		caseStudies: [],
		showQuestionnaire: false,
		allPuzzlesComplete: false,
		userIsAssessmentAndRecruitment: true,
		profileDetailsComplete: false,
		showPaidProfileAd: false,
		deliveriesExist: false,
		inEligibleCountry: false,
		currentPage: -1,
		couponCode: undefined,
	};

	async componentDidMount() {
		TagManager.initialize(tagManagerArgs);
		UserManager.subscribeToSignInChanges(this.handleUserSignInChange);
		await this.loadUserData();
		this.resetPage();
		this.setState({
			loading: false,
		});
	}

	async componentDidUpdate(_prevProps: Props, prevState: State) {
		if (this.state.showPuzzles && prevState.showPuzzles !== this.state.showPuzzles) {
			this.showAppropriatePopUps();
		}
	}

	componentWillUnmount() {
		UserManager.unsubscribeFromSignInChanges(this.handleUserSignInChange);
	}

	handleUserSignInChange = async (_status?: boolean) => {
		this.setState({
			loading: true,
			authUser: await signInAsGuestUserIfRequired(),
		});
		await this.loadUserData();
		this.resetPage();
		this.setState({ loading: false });
	};

	async loadUserData() {
		let {
			authUser,
			interests,
			caseStudies,
			productData,
			completedPuzzles,
			appropriatePuzzles,
			allPuzzlesComplete,
			userRegistration,
			profileDetailsComplete,
			showQuestionnaire,
			showPaidProfileAd,
			deliveriesExist,
			inEligibleCountry,
			userIsAssessmentAndRecruitment,
			couponCode,
		} = this.state;
		const showCreateAccount: boolean = await isSignUpRequired();
		if (showCreateAccount) {
			navigate('/sign-up-required');
			return;
		}
		authUser = await UserManager.getAuthenticatedUser();
		if (!!!authUser) authUser = await signInAsGuestUserIfRequired();
		interests = ((await InterestManager.getInterests()) ?? []).map((interest: Interest) => ({
			...interest,
			selected: false,
		}));
		showPaidProfileAd =
			(await SettingsManager.getSetting('ShowPaidProfileAd', authUser?.id))?.toLocaleLowerCase() ==
			'true';
		deliveriesExist = (await DeliveryManager.getDeliveriesForUser(authUser?.id)) != undefined;
		let ProfileCountriesList: String[] = [];
		try {
			ProfileCountriesList = JSON.parse(
				(await SettingsManager.getSetting('PaidProfileCountriesBlacklist', authUser?.id)) ?? '[]'
			);
		} catch {
			console.error('Unable to parse JSON for countries blacklist');
		}
		inEligibleCountry =
			ProfileCountriesList.find((country) => {
				return country == authUser?.country;
			}) == undefined;
		if (authUser) {
			// LogRocket.identify(`${authenticatedUser.id}`, {
			// 	name: `${authenticatedUser.firstName} ${authenticatedUser.lastName}`,
			// });
			caseStudies = await CaseStudyManager.getActiveCaseStudies();
			productData = await ProductManager.getActiveProductsForUser(authUser);
			completedPuzzles = await PuzzleSessionManager.getCompletedPuzzleIdsForUser(authUser.id);
			appropriatePuzzles = await PuzzleManager.getAppropriatePuzzlesForUser(authUser.id);
			allPuzzlesComplete = await UserManager.allPuzzlesCompleteForUser(authUser.id);
			userRegistration = await RegistrationManager.getMostRecentRegistrationForUser(authUser.id);
			profileDetailsComplete = await UserManager.userHasCompletedProfileDetails(authUser);
			showQuestionnaire = !profileDetailsComplete;
			userIsAssessmentAndRecruitment = await UserManager.isAssessmentRecruitment(authUser);
			couponCode = await CouponCodeManager.getMostRecentFreeCouponCodeForUser(authUser.id);
		}
		this.setState((prevState) => ({
			...prevState,
			authUser,
			interests,
			caseStudies,
			productData,
			completedPuzzles,
			appropriatePuzzles,
			allPuzzlesComplete,
			userRegistration,
			profileDetailsComplete,
			showQuestionnaire,
			showPaidProfileAd,
			deliveriesExist,
			inEligibleCountry,
			userIsAssessmentAndRecruitment,
			couponCode,
		}));
	}

	nextPage() {
		let { currentPage } = this.state;
		let nextPage = currentPage + 1;
		while (nextPage < Object.keys(pages).length) {
			if (this.isEligibleForPage(nextPage)) {
				currentPage = nextPage;
				break;
			}
			nextPage += 1;
		}
		this.setState({ currentPage });
	}

	isEligibleForPage(page: number) {
		const {
			allPuzzlesComplete,
			userIsAssessmentAndRecruitment,
			showQuestionnaire,
			profileDetailsComplete,
			showPaidProfileAd,
			productData,
			inEligibleCountry,
			deliveriesExist,
			authUser,
		} = this.state;
		switch (page) {
			case pages.QuestionnaireSelection: {
				return allPuzzlesComplete && !!showQuestionnaire;
			}
			case pages.AssessmentComplete: {
				return allPuzzlesComplete && profileDetailsComplete;
			}
			case pages.ProfileSelection: {
				return (
					allPuzzlesComplete &&
					profileDetailsComplete &&
					showPaidProfileAd &&
					productData != undefined &&
					inEligibleCountry &&
					!deliveriesExist
				);
			}
			case pages.Interests: {
				return (
					!!!authUser ||
					(!authUser.interests?.length && (authUser.guest || userIsAssessmentAndRecruitment))
				);
			}
			case pages.Puzzles: {
				return true;
			}
			default: {
				return false;
			}
		}
	}

	resetPage() {
		this.setState((prevState) => ({
			...prevState,
			currentPage: -1,
		}));
		this.nextPage();
	}

	showAppropriatePopUps() {
		const { authUser, codingPopupShown } = this.state;
		if (authUser && authUser.interests) {
			// TODO: add a setting or some other controller for when this should appear rather than using hard coded match
			if (authUser.interests.filter((interest) => interest.name == 'Coding').length > 0) {
				PubSub.publish(
					NotificationOperations[NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE],
					{ type: NotificationOperations.ADD_PUZZLESELECTION_AREASOFCODE, text: undefined }
				);
			} else {
				if (!codingPopupShown) {
					const { caseStudies } = this.state;
					const caseStudy = caseStudies[Math.floor(caseStudies.length * Math.random())];
					if (caseStudy) {
						PubSub.publish(
							NotificationOperations[NotificationOperations.ADD_PUZZLECOMPLETE_TECHROLES],
							{
								type: NotificationOperations.ADD_PUZZLECOMPLETE_TECHROLES,
								text: "We've placed candidates in roles such as: ",
								name: caseStudy.name ?? '',
								role: caseStudy.position ?? '',
								description: caseStudy.description ?? '',
								picture: caseStudy.imageURL ?? '',
							}
						);
						this.setState({ codingPopupShown: true });
					}
				}
			}
		}
	}

	render() {
		const {
			loading,
			interests,
			productData,
			authUser,
			allPuzzlesComplete,
			userIsAssessmentAndRecruitment,
			userRegistration,
			currentPage,
		} = this.state;
		const productID = productData ? '?p=' + String(productData[0].id) : '';
		const assessmentCompleteReturnUrl = userRegistration?.returnUrl
			? userRegistration.returnUrl.startsWith('http://') ||
			  userRegistration.returnUrl.startsWith('https://')
				? userRegistration.returnUrl
				: 'https://'.concat(userRegistration.returnUrl)
			: undefined;
		const assessmentCompleteProps = !userIsAssessmentAndRecruitment
			? assessmentCompleteReturnUrl
				? {
						headingText: `Assessment Complete, congratulations! What's Next?`,
						bodyTexts: [
							`Your results are now being collated and will be used in conjunction with the details you have told us to build a skills profile.`,
							`To complete the process, click Next to return back to ${
								userRegistration?.client
									? `the ${userRegistration?.client.name}`
									: 'your referring company'
							} platform`,
						],
				  }
				: this.state.couponCode?.shareProfileWithClient
				? {
						headingText: `Assessment Complete, congratulations! What's Next?`,
						bodyTexts: [
							`Your results are now being collated and will be used in conjunction with the details you have told us to build a skills profile.`,
							`You will hear back from ${
								userRegistration?.client ? userRegistration?.client.name : 'your referring company'
							} in due course.`,
							`In the meantime, why not add any work samples or additional skill qualifications you may have to your Profile?`,
						],
				  }
				: {
						headingText: `Assessment Complete, congratulations! What's Next?`,
						bodyTexts: [
							`Your results are now being collated and will be used in conjunction with the details you have told us to build a skills profile.`,
							`In the meantime, why not add any work samples or additional skill qualifications you may have to your Profile?`,
						],
				  }
			: undefined;
		const profileIsFree = this.state.couponCode?.discount == 1 ? true : false;

		return (
			<div className={styles.welcomeContainer}>
				{!loading ? (
					<>
						{currentPage == pages.QuestionnaireSelection && (
							<>
								<QuestionnaireSelection />
								<div className={styles.puzzleButtonContainer}>
									<ArrowButton
										to={`/profile-details?url=/`}
										caption={'Edit My Profile'}
										onClick={async () => {}}
									/>
									<Link
										to={'/'}
										className={styles.activeElements}
										onClick={async () => {
											this.nextPage();
										}}
									>
										{'NOT RIGHT NOW'}
									</Link>
								</div>
							</>
						)}
						{currentPage == pages.AssessmentComplete && (
							<>
								<AssessmentComplete {...assessmentCompleteProps} />
								{assessmentCompleteReturnUrl ? (
									<div className={styles.puzzleButtonContainer}>
										<ArrowButton
											to={assessmentCompleteReturnUrl}
											caption={'Next'}
											directLink={true}
										/>
									</div>
								) : (
									<div className={styles.puzzleButtonContainer}>
										<ArrowButton
											to={`/profile`}
											caption={'Edit My Profile'}
											onClick={async () => {}}
										/>
										<Link
											to={'/'}
											className={styles.activeElements}
											onClick={async () => {
												this.nextPage();
											}}
										>
											{'NOT RIGHT NOW'}
										</Link>
									</div>
								)}
							</>
						)}
						{currentPage == pages.ProfileSelection && (
							<>
								<ProfileSelection products={this.state.productData} profileIsFree={profileIsFree} />
								<div className={styles.puzzleButtonContainer}>
									<ArrowButton
										to={`/checkout/${productID}`}
										caption={profileIsFree ? 'Claim Now' : 'Purchase Now'}
										onClick={async () => {}}
									/>
									<Link
										to={'/'}
										className={styles.activeElements}
										onClick={async () => {
											this.nextPage();
										}}
									>
										{'NO THANKS'}
									</Link>
								</div>
							</>
						)}
						{currentPage == pages.Interests && (
							<>
								<WelcomeTextBlock />
								<div className={styles.puzzleButtonContainer}>
									<ButtonList
										buttons={interests
											.sort((a, b) => (a.description || '').localeCompare(b.description || ''))
											.map((interest: WelcomeInterest) => {
												return {
													id: interest.id || '',
													title: interest.description || '',
													selected: interest.selected,
												};
											})}
										onStateChange={(id: string) => {
											const interest = interests.find((interest) => interest.id === id);
											if (interest) interest.selected = !interest.selected;
											this.setState({
												interests: [...interests],
											});
										}}
									/>
									<div className={styles.saveInterestBtn}>
										<ArrowButton
											caption={'Save my Interests'}
											onClick={async () => {
												await assignInterests(
													authUser,
													interests.filter((interest) => interest.selected)
												);
												this.nextPage();
											}}
										/>
									</div>
								</div>
							</>
						)}
						{currentPage == pages.Puzzles && (
							<PuzzleTileContainer
								appropriatePuzzles={this.state.appropriatePuzzles}
								completedPuzzles={this.state.completedPuzzles}
								onlyShowRequiredPuzzles={
									!allPuzzlesComplete ||
									this.state.appropriatePuzzles.filter((puzzle) => !puzzle.required).length == 0
								}
							/>
						)}
					</>
				) : (
					<Spinner />
				)}
			</div>
		);
	}
}

export const Root = (props: any) => {
	return (
		<Layout {...props}>
			<WelcomeComponent />
		</Layout>
	);
};

export default Root;
