import React from 'react';
import styles from './styles.module.css';
import { CompletedStar } from 'src/components/SVGs/CompletedStarSVG';

export const CompletedButton = () => {
	return (
		<div className={styles.capsule}>
			<div className={styles.star}>
				<CompletedStar width={18} height={18} />
			</div>
			<div className={styles.label}>COMPLETED</div>
		</div>
	);
};
