import React from 'react';

interface Props {
	width: number;
	height: number;
}

const CompletedStar = (props: Props) => (
	<svg
		width={`${props.width}px`}
		height={`${props.height}px`}
		version="1.1"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<filter id="filter_1">
				<feColorMatrix
					in="SourceGraphic"
					type="matrix"
					values="0 0 0 0 0.20784314 0 0 0 0 0.83137256 0 0 0 0 0.6509804 0 0 0 1 0"
				/>
			</filter>
		</defs>
		<g id="icon-toggle-star_border_24px" filter="url(#filter_1)">
			<path
				d="M15 5.71579L9.6075 5.22632L7.5 0L5.3925 5.23421L0 5.71579L4.095 9.45L2.865 15L7.5 12.0553L12.135 15L10.9125 9.45L15 5.71579L15 5.71579ZM7.5 10.5789L4.68 12.3711L5.43 8.99211L2.94 6.71842L6.225 6.41842L7.5 3.23684L8.7825 6.42632L12.0674 6.72632L9.5775 9L10.3275 12.3789L7.5 10.5789L7.5 10.5789Z"
				id="↳Color"
				fill="#000000"
				fillRule="evenodd"
				stroke="none"
			/>
		</g>
	</svg>
);

export { CompletedStar };
