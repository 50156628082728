import React from 'react';
import styles from './styles.module.css';
import { ProductTile } from 'src/components/ProductTile';
import { Product } from 'src/helpers/ProductManager/ProductManager';
import { CouponCode } from 'src/helpers/CouponCodeManager/CouponCode';

export const ProfileSelection = (props: { products?: Product[]; profileIsFree: boolean }) => {
	return (
		<div className={styles.container}>
			<div className={styles.headingContainer}>
				<h1 className={styles.heading}>Congratulations, your Profile is available.</h1>
				<h1 className={styles.heading}>Take control of your career today!</h1>
				<p className={styles.introText}>
					{props.profileIsFree ? 'Claim' : 'Purchase'} a copy to know your skills and plan your
					career.
				</p>
			</div>
			{props.products &&
				props.products.map((product, i) => {
					return (
						<ProductTile
							key={i}
							name={product.name}
							price={Number(product.price)}
							free={props.profileIsFree}
						/>
					);
				})}
		</div>
	);
};
