import { ApolloManager } from 'src/helpers/ApolloManager';
import getActiveCaseStudies from './getActiveCaseStudies.graphql';

export interface CaseStudy {
	id?: string;
	name?: string;
	position?: string;
	description?: string;
	imageURL?: string;
	active?: boolean;
}

interface CaseStudiesFetchResult {
	allCaseStudies: CaseStudy[];
}

class CaseStudyImplementation {
	public readonly getActiveCaseStudies = async (): Promise<CaseStudy[]> => {
		try {
			const result = await ApolloManager.client.query<CaseStudiesFetchResult>({
				query: getActiveCaseStudies,
			});
			if (result.data.allCaseStudies) {
				return result.data.allCaseStudies;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching all case studies: Error was ${error}`);
		}
		return [];
	};
}

export const CaseStudyManager = new CaseStudyImplementation();
