import React from 'react';
import styles from './styles.module.css';
import { navigate } from 'gatsby';

export const QuestionnaireSelection = () => {
	return (
		<div className={styles.container}>
			<div className={styles.headingContainer}>
				<h1 className={styles.heading}>Puzzles Complete, well done!</h1>
				<p className={styles.introText}>
					Next, tell us more about you by filling in your profile details.
				</p>
			</div>
		</div>
	);
};
