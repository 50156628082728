import React from 'react';
import styles from './styles.module.css';

interface Props {
	headingText?: string;
	bodyTexts?: string[];
}

export const AssessmentComplete = (props: Props) => {
	const fallback = {
		headingText: `Assessment Complete, congratulations! What’s next?`,
		bodyTexts: [
			`Our team will carefully match job opportunities as they arise against the provided profile information and skills demonstrated in the puzzles. In some cases, we may request additional information via email, this is to supplement what you have already told us.`,
			`When we identify a suitable opportunity we will be in touch and may invite you to talk about the role and go through your skills profile in more detail.`,
			`In the meantime, why not add any work samples or additional skill qualifications you may have to your Profile?`,
		],
	};
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.heading}>
					{props.headingText ? props.headingText : fallback.headingText}
				</div>
				{(props.bodyTexts ? props.bodyTexts : fallback.bodyTexts)?.map((element) => {
					return (
						<div key={element} className={styles.text}>
							{element}
						</div>
					);
				})}
			</div>
		</div>
	);
};
