import React from 'react';
import styles from './styles.module.css';
import { BrainSVG } from 'src/components/SVGs/BrainSVG';
import { VisualSpacial } from 'src/components/SVGs/VisualSpacialSVG';
import { MusicalRythmic } from 'src/components/SVGs/MusicalRythmicSVG';
import { Interpersonal } from 'src/components/SVGs/InterpersonalSVG';
import { Naturalistic } from 'src/components/SVGs/NaturalisticSVG';
import { DiceGameSVG } from 'src/components/SVGs/DiceGameSVG';
import { DiscrepanciesSVG } from 'src/components/SVGs/DiscrepanciesSVG';
import { HiddenFiguresSVG } from 'src/components/SVGs/HiddenFiguresSVG';
import { PicturePuzzleSVG } from 'src/components/SVGs/WhereSVG';
import { CompletedButton } from 'src/components/PuzzleTileContainer/components/CompletedButton';
import { Clock } from 'src/components/SVGs/ClockSVG';
import classNames from 'classnames';

interface PuzzleTileData {
	name: string;
	image: string;
	duration: number;
	onClick?: () => void;
	puzzleCompleted?: boolean;
}

const PuzzleTile = (props: PuzzleTileData) => {
	const imageMap: { [key: string]: any } = {
		brain: <BrainSVG width={166} height={92} />,
		visualSpacial: <VisualSpacial width={166} height={92} />,
		musicalRythmic: <MusicalRythmic width={166} height={92} />,
		interpersonal: <Interpersonal width={166} height={92} />,
		naturalistic: <Naturalistic width={166} height={92} />,
		dice: <DiceGameSVG width={166} height={92} />,
		discrepancies: <DiscrepanciesSVG width={166} height={92} />,
		hiddenFigures: <HiddenFiguresSVG width={166} height={92} />,
		number: <BrainSVG width={166} height={92} />,
		where: <PicturePuzzleSVG width={166} height={92} />,
	};
	const image = imageMap[props.image];
	return (
		<div
			className={classNames([styles.tile, props.puzzleCompleted ? styles.completed : undefined])}
			onClick={() => props.onClick && props.onClick()}
		>
			<div className={styles.image}>{image}</div>
			<div className={styles.name}>{props.name}</div>
			{props.puzzleCompleted && <CompletedButton />}
			<div className={styles.duration}>
				<Clock />
				<p>
					Takes about {props.duration} {props.duration == 1 ? 'minute.' : 'minutes.'}
				</p>
			</div>
		</div>
	);
};
export { PuzzleTile };
