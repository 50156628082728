import { ApolloManager } from 'src/helpers/ApolloManager';
import getInterests from './getInterests.graphql';
import { Interest } from 'src/model/Interest';

interface InterestsFetchResult {
	allInterests: Interest[];
}

class InterestImplementation {
	public readonly getInterests = async (): Promise<Interest[] | undefined> => {
		try {
			const result = await ApolloManager.client.query<InterestsFetchResult>({
				query: getInterests,
			});
			if (result.data.allInterests) {
				return result.data.allInterests;
			} else {
				console.error(`Server returned no interests.`);
			}
		} catch (error) {
			console.error(`An error occurred fetching interests: Error was ${error}`);
		}
		return;
	};
}

export const InterestManager = new InterestImplementation();
