import React from 'react';
import { Button } from './components/Button';
import styles from './styles.module.css';

interface ButtonData {
	title: string;
	id: string;
	selected: boolean;
}

interface Props {
	buttons: ButtonData[];
	onStateChange: (id: string) => void;
}

export const ButtonList = (props: Props) => {
	const { buttons, onStateChange } = props;
	return (
		<div className={styles.buttonList} role="button">
			{buttons.map(button => (
				<Button
					key={button.id}
					id={button.id}
					title={button.title}
					selected={button.selected}
					onStateChange={onStateChange}
				/>
			))}
		</div>
	);
};
