import React, { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import styles from './styles.module.css';
import { PuzzleTile } from './components/PuzzleTile';

import { Puzzle } from 'src/model/PuzzleData';

export const PuzzleTileContainer = (props: {
	isHidden?: boolean;
	appropriatePuzzles: Puzzle[];
	completedPuzzles: number[];
	onlyShowRequiredPuzzles?: boolean;
}) => {
	const allPuzzlesComplete =
		props.appropriatePuzzles.filter((puzzle) => {
			return puzzle.required && !props.completedPuzzles.includes(puzzle.id);
		}).length == 0;
	const puzzleText = allPuzzlesComplete ? (
		<div className={styles.puzzleText}>
			<p>All required puzzles have been completed. Well done.</p>
			<p>Feel free to replay puzzles or try some new ones.</p>
		</div>
	) : props.completedPuzzles.length > 0 ? (
		<div className={styles.puzzleText}>
			<p>Which puzzle would you like to try next?</p>
			<p>You need to complete all puzzles below.</p>
		</div>
	) : (
		<p className={styles.puzzleText}>Get started by selecting a puzzle below:</p>
	);
	return (
		<div className={styles.puzzleCardContainer}>
			{!props.isHidden && (
				<>
					<div className={styles.headingContainer}>
						<h1>Puzzles and Challenges</h1>
					</div>
					<div className={styles.lineBreak}></div>
					{puzzleText}
					<div className={styles.lineBreak}></div>
					{!props.onlyShowRequiredPuzzles && (
						<div className={styles.puzzleText}>Required Puzzles</div>
					)}
					<div className={styles.puzzleList}>
						{props.appropriatePuzzles.map((puzzle, i) => {
							return (
								puzzle.required && (
									<Link
										to={`/start-puzzle-${puzzle.id}`}
										state={{ puzzleData: puzzle }}
										key={puzzle.id}
									>
										<PuzzleTile
											name={puzzle.title}
											duration={puzzle.expectedDurationMinutes}
											image={puzzle.titleImage}
											puzzleCompleted={props.completedPuzzles.includes(puzzle.id)}
										/>
									</Link>
								)
							);
						})}
					</div>
					{!props.onlyShowRequiredPuzzles && (
						<div className={styles.puzzleText}>Optional Puzzles</div>
					)}
					{!props.onlyShowRequiredPuzzles && (
						<div className={styles.puzzleList}>
							{props.appropriatePuzzles.map((puzzle, i) => {
								return (
									!puzzle.required && (
										<Link
											to={`/start-puzzle-${puzzle.id}`}
											state={{ puzzleData: puzzle }}
											key={puzzle.id}
										>
											<PuzzleTile
												name={puzzle.title}
												duration={puzzle.expectedDurationMinutes}
												image={puzzle.titleImage}
												puzzleCompleted={props.completedPuzzles.includes(puzzle.id)}
											/>
										</Link>
									)
								);
							})}
						</div>
					)}
				</>
			)}
		</div>
	);
};
