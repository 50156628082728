import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

interface Props {
	title: string;
	id: string;
	selected: boolean;
	onStateChange: (id: string) => void;
}

export function Button(props: Props) {
	const { id, title, selected, onStateChange } = props;
	return (
		<button
			className={classNames(styles.button, {
				[styles.selected]: selected,
				[styles.default]: !selected,
			})}
			onClick={() => onStateChange(id)}
		>
			{title}
		</button>
	);
}
