import React from 'react';
import styles from './styles.module.css';

interface ProductTileData {
	name: string;
	price: number;
	free: boolean;
}

export const ProductTile = (props: ProductTileData) => {
	return (
		<div className={styles.container}>
			<div className={styles.panelList}>
				<div className={styles.panel}>
					<div className={styles.header}>
						<div className={styles.title}>{props.name}</div>
						{props.free ? (
							<div className={styles.pricing}>Claim now, it's free!</div>
						) : (
							<div className={styles.pricing}>
								Buy now for only{' '}
								<ins>
									$
									{props.price.toLocaleString(undefined, {
										minimumFractionDigits: 0,
										maximumFractionDigits: 2,
									})}
								</ins>
							</div>
						)}
					</div>
					<div className={styles.row}>
						<div className={styles.column}>
							<div className={styles.features}>
								<div className={styles.feature}>Your unique skills and support assessment</div>
								<div className={styles.lineBreak} />
								<div className={styles.feature}>Unlimited access to our platform for 12 months</div>
								<div className={styles.lineBreak} />
								<div className={styles.feature}>Expand your skills with new assessments</div>
								<div className={styles.lineBreak} />
								<div className={styles.feature}>Free entry into Xceptional webinars</div>
								<div className={styles.lineBreak} />
								<div className={styles.feature}>
									Free subscriptions to the Xceptional newsletter
								</div>
							</div>
						</div>
						<div className={styles.column}>
							<div className={styles.image}>
								<a href="/images/profile_large.png" target="_blank">
									<img src="/images/profile.png" alt="Profile Example" />
								</a>
								<div className={styles.ndis}>Click image to enlarge</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
