import React from 'react';
import styles from './styles.module.css';
export const WelcomeTextBlock = () => {
	return (
		<div className={styles.headingContainer}>
			<h1 className={styles.heading}>Welcome to Xceptional</h1>
			<p className={styles.introText}>
				To help us suggest some relevant puzzles for you to show off your skills, tell us below what
				your interests and hobbies are.
			</p>
			<p className={styles.introText}>Select as many as you like:</p>
		</div>
	);
};
