import { SettingsManager } from './SettingsManager';
import { UserManager } from './UserManager';
import { PuzzleSessionManager } from './PuzzleSessionManager';

const isSignUpRequired = async (): Promise<boolean> => {
	const isGuest = await UserManager.isGuestUser();
	if (isGuest) {
		const userId = await UserManager.getAuthenticatedUserId();
		const threshold = await getThresholdValue(userId);
		const completedPuzzleCount = await getCompletedPuzzleCount(userId);
		return threshold > 0 && completedPuzzleCount >= threshold;
	}
	return false;
};

const getThresholdValue = async (userId?: number) => {
	const value = await SettingsManager.getSetting('EnforceCreateAccountThreshold', userId);
	const threshold: number = Number(value);
	return Number.isNaN(threshold) ? 0 : threshold;
};

const getCompletedPuzzleCount = async (userId?: number): Promise<number> => {
	if (userId) {
		return await PuzzleSessionManager.getCompletedPuzzleCount(userId);
	}
	return 0;
};

export { isSignUpRequired };
